<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { summaryListAPI, sumDetailGoodsSummaryForms } from "@/api/purchase/report"; //采购报表
export default {
  name: "payQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: '05f1597e-7dfa-d5ed-7a57-b0aa0242747c',
        body: {},
        title: "采购汇总表",
        rowKey: "billId",
        labelWidth: "70",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据类型",
            type: "local",
            model: "",
            filter: "billTypes",
            seniorSearch: true,
            hide: () => ["billTypeGoods", "billTypeGoodsCategory", 'billTypePartner', 'billTypeStore'].includes(this.options.tabColumnType),
            option: {
              data: [
                { label: "采购入库", value: "110102" },
                { label: "采购退货", value: "110103" },
                {
                  label: "门店采购",
                  value: "140303",
                },
                {
                  label: "门店退货",
                  value: "140318",
                },
              ],
              label: "label",
              value: "value",
              filterable: true,
              multiple: true,
            },
          },
          this.$select({
            key: "listCategory",
            option: {
              seniorSearch: true,
              hide: () => ["billTypeGoods", "billTypeGoodsCategory", "goods", "goodsCategory", 'partnerStoreGoods', 'partnerStoreGoodsCategory'].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              seniorSearch: true,
              hide: () => ["billTypeGoods", "goods", "partnerStoreGoods"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              seniorSearch: true,
              hide: () => ["partner", "billTypePartner", "partnerStoreGoods", "partnerStoreGoodsCategory"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              hide: () => ["store", "billTypeStore", "partnerStoreGoods", "partnerStoreGoodsCategory"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurCreateEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurAuditEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "billTypeGoods",
        getListApi: summaryListAPI,
        getSummaryApi: sumDetailGoodsSummaryForms,
        tabsColumns: [
          {
            title: "单据类型+商品汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '单据类型+商品汇总'
            },
            uuid: '05f1597e-7dfa-d5ed-7a57-b0aa02427335',
            defaultBody: { searchType: "billTypeGoods" },
            type: "billTypeGoods",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编号",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 100,
              },
              {
                prop: "basUnitPrice",
                label: "基本单位单价",
                minWidth: 110,
              },
              {
                prop: "basUnitTaxPrice",
                label: "基本单位含税单价",
                minWidth: 130,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 80,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 120,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 120,
              }
            ],
            summary: ['basUnitQty', "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "单据类型+商品类别汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '单据类型+商品类别汇总'
            },
            uuid: 'aeb19c23-15cf-9dd3-b840-089ed7a5bcc0',
            defaultBody: { searchType: "billTypeGoodsCategory" },
            type: "billTypeGoodsCategory",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 120,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 120,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 120,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "单据类型+供应商汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '单据类型+供应商汇总'
            },
            uuid: '056bd029-2bcf-5666-40aa-bdafe469a226',
            defaultBody: { searchType: "billTypePartner" },
            type: "billTypePartner",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "partnerNo",
                label: "供应商编号",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 120,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 120,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 120,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "单据类型+仓库汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '单据类型+仓库汇总'
            },
            uuid: '7e864171-3ee8-ad22-451c-db83838e512x',
            defaultBody: { searchType: "billTypeStore" },
            type: "billTypeStore",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "storeNo",
                label: "仓库编号",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 120,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 120,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 120,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "商品汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '商品汇总'
            },
            uuid: '05f1597e-7dfa-d5ed-7a57-b0aa0242747c',
            defaultBody: { searchType: "goods" },
            type: "goods",
            columns: [
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编号",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 100,
              },
              {
                prop: "basUnitPrice",
                label: "基本单位单价",
                minWidth: 100,
              },
              {
                prop: "basUnitTaxPrice",
                label: "基本单位含税单价",
                minWidth: 130,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 80,
              },
              {
                prop: "basUnitQty",
                label: "实际数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "实际不含税金额",
                minWidth: 130,
              },
              {
                prop: "unitTaxMoney",
                label: "实际税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "实际价税合计",
                minWidth: 120,
              },
              {
                prop: "purBasUnitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "purUnitMoney",
                label: "入库不含税金额",
                minWidth: 140,
              },
              {
                prop: "purUnitTaxMoney",
                label: "入库税额",
                minWidth: 120,
              },
              {
                prop: "purUnitPriceTaxMoney",
                label: "入库价税合计",
                minWidth: 130,
              },
              {
                prop: "purBackBasUnitQty",
                label: "退货数量",
                minWidth: 120,
              },
              {
                prop: "purBackUnitMoney",
                label: "退货不含税金额",
                minWidth: 140,
              },
              {
                prop: "purBackUnitTaxMoney",
                label: "退货税额",
                minWidth: 120,
              },
              {
                prop: "purBackUnitPriceTaxMoney",
                label: "退货价税合计",
                minWidth: 130,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney", 'purBasUnitQty', 'purUnitMoney', 'purUnitTaxMoney', 'purUnitPriceTaxMoney', 'purBackBasUnitQty', 'purBackUnitMoney', 'purBackUnitTaxMoney', 'purBackUnitPriceTaxMoney'],
          },
          {
            title: "商品类别汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '商品类别汇总'
            },
            uuid: 'aeb19c23-15cf-9dd3-b840-089ed7a5bcc9',
            defaultBody: { searchType: "goodsCategory" },
            type: "goodsCategory",
            columns: [
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "实际数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "实际不含税金额",
                minWidth: 130,
              },
              {
                prop: "unitTaxMoney",
                label: "实际税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "实际价税合计",
                minWidth: 120,
              },
              {
                prop: "purBasUnitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "purUnitMoney",
                label: "入库不含税金额",
                minWidth: 140,
              },
              {
                prop: "purUnitTaxMoney",
                label: "入库税额",
                minWidth: 120,
              },
              {
                prop: "purUnitPriceTaxMoney",
                label: "入库价税合计",
                minWidth: 130,
              },
              {
                prop: "purBackBasUnitQty",
                label: "退货数量",
                minWidth: 120,
              },
              {
                prop: "purBackUnitMoney",
                label: "退货不含税金额",
                minWidth: 140,
              },
              {
                prop: "purBackUnitTaxMoney",
                label: "退货税额",
                minWidth: 120,
              },
              {
                prop: "purBackUnitPriceTaxMoney",
                label: "退货价税合计",
                minWidth: 130,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney", 'purBasUnitQty', 'purUnitMoney', 'purUnitTaxMoney', 'purUnitPriceTaxMoney', 'purBackBasUnitQty', 'purBackUnitMoney', 'purBackUnitTaxMoney', 'purBackUnitPriceTaxMoney'],
          },
          {
            title: "供应商汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '供应商汇总'
            },
            uuid: '056bd029-2bcf-5666-40aa-bdafe469a220',
            defaultBody: { searchType: "partner" },
            type: "partner",
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编号",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "实际数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "实际不含税金额",
                minWidth: 130,
              },
              {
                prop: "unitTaxMoney",
                label: "实际税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "实际价税合计",
                minWidth: 120,
              },
              {
                prop: "purBasUnitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "purUnitMoney",
                label: "入库不含税金额",
                minWidth: 140,
              },
              {
                prop: "purUnitTaxMoney",
                label: "入库税额",
                minWidth: 120,
              },
              {
                prop: "purUnitPriceTaxMoney",
                label: "入库价税合计",
                minWidth: 130,
              },
              {
                prop: "purBackBasUnitQty",
                label: "退货数量",
                minWidth: 120,
              },
              {
                prop: "purBackUnitMoney",
                label: "退货不含税金额",
                minWidth: 140,
              },
              {
                prop: "purBackUnitTaxMoney",
                label: "退货税额",
                minWidth: 120,
              },
              {
                prop: "purBackUnitPriceTaxMoney",
                label: "退货价税合计",
                minWidth: 130,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney", 'purBasUnitQty', 'purUnitMoney', 'purUnitTaxMoney', 'purUnitPriceTaxMoney', 'purBackBasUnitQty', 'purBackUnitMoney', 'purBackUnitTaxMoney', 'purBackUnitPriceTaxMoney'],
          },
          {
            title: "仓库汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '仓库汇总'
            },
            uuid: '7e864171-3ee8-ad22-451c-db83838e512c',
            defaultBody: { searchType: "store" },
            type: "store",
            columns: [
              {
                prop: "storeNo",
                label: "仓库编号",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "实际数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "实际不含税金额",
                minWidth: 130,
              },
              {
                prop: "unitTaxMoney",
                label: "实际税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "实际价税合计",
                minWidth: 120,
              },
              {
                prop: "purBasUnitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "purUnitMoney",
                label: "入库不含税金额",
                minWidth: 140,
              },
              {
                prop: "purUnitTaxMoney",
                label: "入库税额",
                minWidth: 120,
              },
              {
                prop: "purUnitPriceTaxMoney",
                label: "入库价税合计",
                minWidth: 130,
              },
              {
                prop: "purBackBasUnitQty",
                label: "退货数量",
                minWidth: 120,
              },
              {
                prop: "purBackUnitMoney",
                label: "退货不含税金额",
                minWidth: 140,
              },
              {
                prop: "purBackUnitTaxMoney",
                label: "退货税额",
                minWidth: 120,
              },
              {
                prop: "purBackUnitPriceTaxMoney",
                label: "退货价税合计",
                minWidth: 130,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney", 'purBasUnitQty', 'purUnitMoney', 'purUnitTaxMoney', 'purUnitPriceTaxMoney', 'purBackBasUnitQty', 'purBackUnitMoney', 'purBackUnitTaxMoney', 'purBackUnitPriceTaxMoney'],
          },
          {
            title: "供应商+仓库+商品汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '供应商+仓库+商品汇总'
            },
            uuid: '05f1597e-7dfa-d5ed-7a57-b0aa0242747e',
            defaultBody: { searchType: "partnerStoreGoods" },
            type: "partnerStoreGoods",
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编号",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "storeNo",
                label: "仓库编号",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编号",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 100,
              },
              {
                prop: "basUnitPrice",
                label: "基本单位单价",
                minWidth: 100,
              },
              {
                prop: "basUnitTaxPrice",
                label: "基本单位含税单价",
                minWidth: 130,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 80,
              },
              {
                prop: "basUnitQty",
                label: "实际数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "实际不含税金额",
                minWidth: 130,
              },
              {
                prop: "unitTaxMoney",
                label: "实际税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "实际价税合计",
                minWidth: 120,
              },
              {
                prop: "purBasUnitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "purUnitMoney",
                label: "入库不含税金额",
                minWidth: 140,
              },
              {
                prop: "purUnitTaxMoney",
                label: "入库税额",
                minWidth: 120,
              },
              {
                prop: "purUnitPriceTaxMoney",
                label: "入库价税合计",
                minWidth: 130,
              },
              {
                prop: "purBackBasUnitQty",
                label: "退货数量",
                minWidth: 120,
              },
              {
                prop: "purBackUnitMoney",
                label: "退货不含税金额",
                minWidth: 140,
              },
              {
                prop: "purBackUnitTaxMoney",
                label: "退货税额",
                minWidth: 120,
              },
              {
                prop: "purBackUnitPriceTaxMoney",
                label: "退货价税合计",
                minWidth: 130,
              }
            ],
            summary: ["basUnitQty", "basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney", 'purBasUnitQty', 'purUnitMoney', 'purUnitTaxMoney', 'purUnitPriceTaxMoney', 'purBackBasUnitQty', 'purBackUnitMoney', 'purBackUnitTaxMoney', 'purBackUnitPriceTaxMoney'],
          },
          {
            title: "供应商+仓库+商品类别汇总",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '供应商+仓库+商品类别汇总'
            },
            uuid: 'aeb19c23-15cf-9dd3-b840-089ed7a5bcc00',
            defaultBody: { searchType: "partnerStoreGoodsCategory" },
            type: "partnerStoreGoodsCategory",
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编号",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "storeNo",
                label: "仓库编号",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "实际数量",
                minWidth: 110,
              },
              {
                prop: "billNotaxMoney",
                label: "实际不含税金额",
                minWidth: 130,
              },
              {
                prop: "unitTaxMoney",
                label: "实际税额",
                minWidth: 120,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "实际价税合计",
                minWidth: 120,
              },
              {
                prop: "purBasUnitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "purUnitMoney",
                label: "入库不含税金额",
                minWidth: 140,
              },
              {
                prop: "purUnitTaxMoney",
                label: "入库税额",
                minWidth: 120,
              },
              {
                prop: "purUnitPriceTaxMoney",
                label: "入库价税合计",
                minWidth: 130,
              },
              {
                prop: "purBackBasUnitQty",
                label: "退货数量",
                minWidth: 120,
              },
              {
                prop: "purBackUnitMoney",
                label: "退货不含税金额",
                minWidth: 140,
              },
              {
                prop: "purBackUnitTaxMoney",
                label: "退货税额",
                minWidth: 120,
              },
              {
                prop: "purBackUnitPriceTaxMoney",
                label: "退货价税合计",
                minWidth: 130,
              }
            ],
            summary: ["basUnitQty", "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney", 'purBasUnitQty', 'purUnitMoney', 'purUnitTaxMoney', 'purUnitPriceTaxMoney', 'purBackBasUnitQty', 'purBackUnitMoney', 'purBackUnitTaxMoney', 'purBackUnitPriceTaxMoney'],
          },
        ],
      },
    };
  },
};
</script>
